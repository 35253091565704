import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Login from '@/views/login/login'
import Content from '@/views/content/content'
import Home from '@/views/home/index'
import Screen from '@/views/screen/screen'
import Feed from '@/views/feed/feed'
import Process from '@/views/process/process'
import Trade from '@/views/trade/trade'
import Detail from '@/views/detail/detail'



const router = new VueRouter({
    mode:'hash',
    routes:[
        {
            path:'/login',
            name: "loginPage",
            component: Login
        },
        {
            path:'/',
            name: "contentPage",
            component: Content,
            redirect:"/home",
            children:[
                {
                    path:'/home',
                    name: "homePage",
                    component: Home
                },
                {
                    path:'/screen',
                    name: "screenPage",
                    component: Screen
                },
                {
                    path:'/feed',
                    name: "feedPage",
                    component: Feed
                },
                {
                    path:'/process',
                    name: "processPage",
                    component: Process
                },
                {
                    path:'/trade',
                    name: "tradePage",
                    component: Trade
                },
                {
                    path:'/detail',
                    name: "detailPage",
                    component: Detail
                }
            ]
        }
    ]
})
export default router


