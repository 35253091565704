<template>
  <div class="main">
    <div class="main-top">
      <div class="main-top-left">
        <swiper style="width:100%;height:100%;"
                class="mySwiper"
                :options="swiperOption"
                ref="mySwiper">
          <swiper-slide class="slide-item slide-item1">
            <div class="mask" @click="toDetail(4,1)"></div>
            <div class="slide-item-label">光阳蛋业董事长余劼：让传统行业插上数字化的翅膀</div>
          </swiper-slide>
          <swiper-slide class="slide-item slide-item2" @click="toDetail(5,1)">
            <div class="mask" @click="toDetail(5,1)"></div>
            <div class="slide-item-label">全球首台商用蛋鸡养殖机器人“木鸡郎4”首发，并下地运行</div>
          </swiper-slide>
          <swiper-slide class="slide-item slide-item3" @click="toDetail(6,1)">
            <div class="mask" @click="toDetail(6,1)"></div>
            <div class="slide-item-label">风云闽商 | 年加工蛋品10万吨！看一枚蛋的“数字化”智造”</div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="main-top-right">
        <el-card class="card box-card">
          <div slot="header" class="clearfix card-title">
            <div class="card-title-left">
              <img src="~@/assets/title/1.png">
              <span>企业新闻</span>
            </div>
            <div class="card-title-right">
              <span>更多</span>
              <img src="~@/assets/title/right.png">
            </div>
          </div>
          <div class="card-content" style="height: 100%;">
            <div v-for="(item,index) in cardList" :key="index" class="card-list" @click="toDetail(item.id,1)">
              <div class="card-list-left"><div class="dot"></div>{{item.title}}</div>
              <div class="card-list-right">{{item.date}}</div>
            </div>
          </div>

        </el-card>
      </div>
    </div>
    <div class="main-middle">
      <div class="main-middle-left">
        <div>应用</div>
        <div>中心</div>
      </div>
      <div class="main-middle-line"></div>
      <div class="main-middle-right">
        <div class="middle-right-list" @click="toOtherPlatform(item)" v-for="(item,index) in list" :key="index">
          <img :src="item.imgUrl" />
          <div class="right-list-label">{{item.label}}</div>
          <div class="right-list-go"><img src="~@/assets/title/right.png"></div>
        </div>
      </div>
    </div>
    <div class="main-bottom">
      <div class="main-bottom-left">
        <el-card class="card box-card">
          <div slot="header" class="clearfix card-title">
            <div class="card-title-left" >
              <img src="~@/assets/title/2.png">
              <span>通知公告</span>
            </div>
            <div class="card-title-right">
              <span>更多</span>
              <img src="~@/assets/title/right.png">
            </div>
          </div>
          <div class="card-content" style="height: 100%;">
            <div v-for="(item,index) in cardList.slice(0,4)" :key="index" class="card-list" @click="toDetail(item.id,2)">
              <div class="card-list-left"><div class="dot"></div>{{item.title}}</div>
              <div class="card-list-right">{{item.date}}</div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="main-bottom-right">
        <el-card class="card box-card">
          <div slot="header" class="clearfix card-title">
            <div class="card-title-left" >
              <img src="~@/assets/title/3.png">
              <span>消息中心</span>
            </div>
            <div class="card-title-right">
              <span>更多</span>
              <img src="~@/assets/title/right.png">
            </div>
          </div>
          <div class="card-content" style="height: 100%;">
            <div v-for="(item,index) in cardList.slice(0,4)" :key="index" class="card-list" @click="toDetail(item.id,3)">
              <div class="card-list-left"><div class="dot"></div>{{item.title}}</div>
              <div class="card-list-right">{{item.date}}</div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homePage",
  data(){
    return {
      swiperOption:{
        autoplay: true,
        observer: true,
        observeParents: true,
        observeSlideChildren:true,
        mousewheel: true,
        updateOnWindowResize: true,
        autoHeight:true,
        speed: 800,
        pagination: {
          el: '.swiper-pagination',
          clickable :true
        },
        parallax : true,
        effect: 'none',
        fadeEffect: {
          crossFade: true,
        },
      },
      list:[
        {label:'金蝶ERP',imgUrl:require('@/assets/ERP.png'),url:'http://192.168.11.3:90/k3cloud'},
        {label:'养殖平台',imgUrl:require('@/assets/mjl.png'),url:'https://breed.chinaegg.com.cn'},
        {label:'财务管理',imgUrl:require('@/assets/money.png'),url:'http://192.168.11.3:90/k3cloud'},
        {label:'办公OA',imgUrl:require('@/assets/OA.png'),url:'http://192.168.11.5:8080'},
      ],
      cardList:[
        {title:'“新华全媒+｜数字时代的饲养员有什么新体验”',date:'2023-07-24',id:1},
        {title:'福州数字先锋 光阳蛋业：数字技术赋能 蛋禽养殖进入快车道”',date:'2023-03-13',id:2},
        {title:'蛋鸡养殖业迈向数字化、智能化：新一代“木鸡郎”投用',date:'2023-06-22',id:3},
        {title:'光阳蛋业董事长余劼：让传统行业插上数字化的翅膀',date:'2023-05-19',id:4},
        {title:'全球首台商用蛋鸡养殖机器人“木鸡郎4”首发，并下地运行',date:'2023-06-04',id:5},
        {title:'风云闽商 | 年加工蛋品10万吨！看一枚蛋的“数字化”智造”',date:'2023-04-26',id:6}
      ]
    }
  },
  created() {
    const userName = sessionStorage.getItem('userName')
    if(!userName){
      this.$router.push({
        path:'/login'
      })
    }
  },
  mounted() {

  },
  methods:{
    toOtherPlatform(item){
      if(item.url){
        window.open(item.url)
      }
    },
    toDetail(id,type){
      console.log(id,type)
      this.$router.push({
        path:'/detail?id='+id+'&type='+type
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.main{
  width: 100vw;height: 87vh;
  padding: 2vh 4vw;box-sizing: border-box;
  // 卡片 公共
  .box-card{
    box-shadow:none!important;
    ::v-deep .el-card__header{
      padding: 0px 35px!important;
      height: 6vh;line-height: 6vh;
    }
    ::v-deep .el-card__body{
      padding: 20px 35px!important;box-sizing: border-box;
      height: calc(100% - 6vh);
    }
    .card-title{
      width: 100%;
      display: flex;align-items: center;justify-content: space-between;
      .card-title-left{
        display: flex;align-items: center;
        img{
          width: 3vh;height: 3vh;
          margin-right: 0.5vw;
        }
        span{
          font-weight: bold;font-size: 2.4vh;
        }
      }
      .card-title-right{
        display: flex;align-items: center;
        //cursor: pointer;
        span{
          color: #999;margin-right: 0.1vw;
          font-size: 1.6vh;
        }
        img{
          width: 1.6vh;height: 1.6vh;
        }
      }
    }
    .card-content{
      display: flex;flex-direction: column;justify-content: space-between;
      .card-list{
        //height: 4.4vh;line-height: 4.4vh;
        display: flex;justify-content: space-between;align-items: center;

        .card-list-left{
          width: 79%;
          color: #666;
          font-size: 2vh;
          display: flex;align-items: center;
          cursor: pointer;
          .dot{
            width: 0.6vh;height: 0.6vh;
            background-color: #666;
            border-radius: 50%;
            margin-right: 0.5vw;
          }
        }
        .card-list-right{
          width: 19%;
          text-align: right;
          color: #808080;
          font-size: 1.8vh;
        }
      }
    }

  }

  // 中上
  .main-top{
    height: 36vh!important;
    display: flex;align-items: center;justify-content: space-between;
    .main-top-left{
      width: 45vw;height: 100%;
      .slide-item{
        width: 100%;height: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        cursor: pointer;
        .mask{
          width: 100%;height: 100%;
          position: absolute;left: 0;top: 0;
        }
        .slide-item-label{
          width: 100%;height: 5vh;line-height: 5vh;
          font-size: 2vh;
          position: absolute;left: 0;bottom: 0;
          color: #fff;
          background: rgba(#333,0.2);
          padding-left: 0.5vw;box-sizing: border-box;
        }
      }
      .slide-item1{
        background-image: url("@/assets/slide/4.jpeg");
      }
      .slide-item2{
        background-image: url("@/assets/slide/5.jpeg");
      }
      .slide-item3{
        background-image: url("@/assets/slide/61.png");
      }
      ::v-deep .swiper-pagination-bullets{
        bottom: 1.8vh!important;
        left: 45%!important;
        .swiper-pagination-bullet{
          background: #B3B1B2!important;
        }
        .swiper-pagination-bullet-active{
          background: #F3F6FB!important;
          width: 18px!important;
          //height: 8px!important;
          border-radius: 8px!important;
        }

      }
    }
    .main-top-right{
      width: 45vw;height: 100%;
      .box-card{
        width: 100%;height: 100%;
      }
    }
  }
  // 中中
  .main-middle{
    height: 10vh;width: 100%;
    margin: 3vh 0;
    display: flex;align-items: center;
    background: #EBF5FE;
    .main-middle-left{
      width: 4.6vw;text-align: center;
      >div{
        font-weight: bold;font-size: 1.8vh;
        color: #2286EC;
      }
    }
    .main-middle-line{
      width: 2px;
      height: 6vh;
      background: linear-gradient(0deg, rgba(232,243,253,0.6),rgba(34,134,236,0.6), rgba(232,243,253,0.6));
    }
    .main-middle-right{
      display: flex;align-items: center;justify-content: space-between;
      width: calc(100% - 4.6vw - 2px);
      padding: 0 2vw 0 4vw;box-sizing: border-box;
      .middle-right-list{
        display: flex;align-items: center;
        background: #fff;
        border-radius: 35px;
        padding: 0.6vh 1.5vw;
        cursor: pointer;
        img{width: 4vh;height: 4vh;}
        .right-list-label{
          font-size: 1.2vw;font-weight: bold;
          margin-left: 0.4vw;
          margin-right: 2vw;
        }
        .right-list-go{
          width: 3vh;height: 3vh;
          display: flex;justify-content: center;align-items: center;
          border: 1px solid #ccc;border-radius: 50%;
          img{
            width: 1.6vh;height:1.6vh;
          }
        }
      }
    }
  }
  // 中下
  .main-bottom{
    height: 32vh!important;
    display: flex;align-items: center;justify-content: space-between;
    >div{
      width: 45vw;height: 100%;
      .box-card{
        width: 100%;height: 100%;
      }
    }
    .main-bottom-left{


    }
    .main-bottom-right{

    }
  }
}
</style>
