<template>
  <div class="login-page">
    <div class="login-box">
      <img src="~@/assets/login/logo.png">
      <div class="login-title">光阳蛋业企业管理平台</div>
      <div class="login-form">
        <el-form ref="form" :model="form" :rules="rules" label-width="0px">
          <el-form-item prop="username">
            <el-input v-model="form.username" prefix-icon="el-icon-user" clearable placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input @keyup.enter.native="login" v-model="form.password" prefix-icon="el-icon-unlock" clearable placeholder="密码" type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="login-btn" @click="login">登录</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loginPage",
  data(){
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
    }
  },
  mounted() {

  },
  methods:{
    login(){
      if(!this.form.username){
        return
      }
      if(!this.form.password){
        return
      }
      if(this.form.username==='admin' && this.form.password==='gy2023'){
        sessionStorage.setItem('userName',this.form.username)
        this.$router.push({
          path:'/'
        })
      }else{
        this.$message({
          message: '用户名或密码错误',
          type: 'warning'
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page{
  width: 100vw;height: 100vh;
  background-image: url("~@/assets/login/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 50%;
  display: flex;justify-content: center;align-items: center;
  .login-box{
    width: 24vw;height: 70vh;
    padding: 3vh 3vw;box-sizing: border-box;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 5px 0px #cecece;
    display: flex;align-items: center;flex-direction: column;
    img{
      width: 40%;height: auto;
    }
    .login-title{
      color: #409EFF;
      font-weight: bold;font-size: 3vh;
      margin-bottom: 4vh;
    }
    .login-form{
      width: 100%;
      ::v-deep input{
        width: 100%;
      }
    }
    .login-btn{
      width: 100%;
      text-align: center;
      font-weight: bold;font-size: 1.8vh;
      padding: 10px 0;
      margin-top: 4vh;
      background-color: #409EFF;color: #fff;
      cursor: pointer;
    }
  }
}
</style>
