<template>
  <div class="feed-page">
    feed-page
  </div>
</template>

<script>
export default {
  name: "feedPage",
  data(){
    return {

    }
  },
  mounted() {

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.feed-page{
  width: 100vw;height: 88vh;
  padding: 2vh 4vw;box-sizing: border-box;
}
</style>
