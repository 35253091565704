<template>
  <div class="header">
    <div class="header-left" @click="toIndex">光阳蛋业信息门户</div>
    <div class="header-right">
      <div
        v-for="(item,index) in tabs" :key="item.name"
        :class="curIndex===index?'active':''"
        @click="changeTab(item,index)"
      >
        {{item.name}}
      </div>
      <div class="login-out">
        <el-popover
            placement="top-start"
            width="150"
            trigger="hover">
          <div @click="logout" style="text-align: center;font-weight: bold;font-size: 1.6vh;cursor: pointer;">退出登录</div>
          <div slot="reference">admin<i class="el-icon-caret-bottom"></i></div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "top-header",
  data(){
    return {
      tabs:[
        { name: '首页', path: '/' },
        { name: '数据大屏', path: '/screen' },
        { name: '养殖板块', path: '/feed', disabled: true },
        { name: '加工板块', path: '/process', disabled: true },
        { name: '贸易板块', path: '/trade', disabled: true},
      ],
      curIndex: 0,

    }
  },
  mounted() {

  },
  methods:{
    toIndex(){
      this.$router.push({
        path:'/'
      })
    },
    changeTab(item,index){
      console.log('==item===',item)
      if(item.disabled){
        return
      }else{
        if(item.name=='数据大屏'){
          window.open('https://breed.chinaegg.com.cn/#/monitoring')
        }else{
          this.curIndex = index
          this.$router.push({
            path: item.path
          })
        }
      }
    },
    logout(){
      sessionStorage.setItem('userName','')
      this.$router.push({
        path:'/login'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header{
  height: 7.4vh!important;
  background-color: #2288EA;
  display: flex;justify-content: space-between;align-items: center;
  padding: 0 4vw;box-sizing: border-box;

  .header-left{
    color: #fff;
    font-size: 2.5vh;
    cursor: pointer;
  }
  .header-right{
    display: flex;height: 100%;line-height: 8vh;
    >div{
      height: 100%;
      color: #fff;
      padding: 0 2vw;
      font-size: 2vh;
      box-sizing: border-box;
      cursor: pointer;
    }
    .active{
      font-weight: bold;
      background: rgba(#0E4984,0.1);
      height: 100%;
      border-bottom: 3px solid #fff;
    }
    .login-out{
      padding: 0 0 0 2vw;
      display: flex;align-items: center;
      >div{
        font-size: 1.5vh;
      }
    }
  }
}
</style>
