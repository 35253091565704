<template>
  <div class="footer">版权所有：福建光阳蛋业有限公司 联系地址：福州市福清市 闽ICP备11011339号 邮政编码：350002</div>
</template>

<script>
export default {
  name: "bottom-footer",
  data(){
    return {

    }
  },
  mounted() {
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.footer{
  height: 4vh!important;
  background-color: #f6f6f6;
  display: flex;justify-content: center;align-items: center;
  color: #666;font-size: 1.4vh;
}
</style>
