<template>
  <div class="detail-page">
    <!--  面包屑  -->
    <div class="breadcrumb-wrap">
      <div class="icon-text"></div>
      <div class="type-text">{{type===1?'企业新闻':type===2?'通知公告':'消息中心'}}</div>
      <div class="row-text"> > </div>
      <div class="detail-text">内容详情</div>
    </div>
    <!--  标题  -->
    <div class="title-wrap">
      <div class="title-text">{{titleText}}</div>
      <div class="date-text">{{titleDate}}</div>
    </div>
    <!--  内容  -->
    <div class="content-wrap">
      <div style="width: 100%;height: 71vh;" v-if="cardList.find(v=>v.id===id)">
        <iframe frameborder="0" width="101%" height="100%" :src="`detail/${id}.html`"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detailPage",
  data(){
    return {
      id: null,
      type: null,
      cardList:[
        {title:'“新华全媒+｜数字时代的饲养员有什么新体验”',date:'2023-07-24',id:1},
        {title:'福州数字先锋 光阳蛋业：数字技术赋能 蛋禽养殖进入快车道”',date:'2023-07-13',id:2},
        {title:'蛋鸡养殖业迈向数字化、智能化：新一代“木鸡郎”投用',date:'2023-06-22',id:3},
        {title:'光阳蛋业董事长余劼：让传统行业插上数字化的翅膀',date:'2023-05-19',id:4},
        {title:'全球首台商用蛋鸡养殖机器人“木鸡郎4”首发，并下地运行',date:'2023-06-04',id:5},
        {title:'风云闽商 | 年加工蛋品10万吨！看一枚蛋的“数字化”智造”',date:'2023-04-26',id:6}
      ]
    }
  },
  mounted() {
    this.id = Number(this.$route.query.id)
    this.type = Number(this.$route.query.type)
    console.log('this.id',this.id)
  },
  computed:{
    titleText(){
        const title = this.cardList.find(v=>v.id === this.id).title
        return title
    },
    titleDate(){
      const date = this.cardList.find(v=>v.id === this.id).date
      return date
    }
  },
  methods:{
    // formatDate(date){
    //   const year = date.getFullYear()
    //   let month = date.getMonth() + 1
    //   let day = date.getDate()
    //   if (month < 10) {
    //     month = `0${month}`
    //   }
    //   if (day < 10) {
    //     day = `0${day}`
    //   }
    //   return (`${year}-${month}-${day}`)
    // }
  }
}
</script>

<style lang="scss" scoped>
.detail-page{
  min-height: 88vh;
  padding: 2vh 4vw;box-sizing: border-box;

  //面包屑
  .breadcrumb-wrap{
    display: flex;align-items: center;
    margin-bottom: 3vh;
    >div{
      font-size: 1.6vh;
    }
    .icon-text{
      width: 4px;height: 1.7vh;
      background-color: #409EFF;
      margin-right: 5px;
    }
    .type-text{
      font-weight: bold;
    }
    .row-text{
      margin: 2px 6px 0 6px;
    }
    .detail-text{
      color: #666;
    }
  }

  //标题
  .title-wrap{
    padding-bottom: 1.5vh;
    border-bottom: 1px solid #eee;
    .title-text{
      font-size: 2.5vh;font-weight: bold;
      margin-bottom: 1.6vh;
    }
    .date-text{
      color: #666;
      font-size: 1.5vh;
    }
  }

  .content-wrap{
    overflow: hidden;
    //iframe{
    //  overflow-x: hidden; overflow-y: scroll;
    //}
  }
}
</style>
