<template>
  <div>
    <top-header></top-header>
    <router-view></router-view>
    <bottom-footer></bottom-footer>
  </div>
</template>

<script>
import topHeader from '../../components/top-header/top-header'
import bottomFooter from '../../components/bottom-footer/bottom-footer'
export default {
  name: "contentPage",
  components: {
    topHeader, bottomFooter
  }
}
</script>

<style scoped>

</style>
